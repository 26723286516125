exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-global-context-js": () => import("./../../../src/pages/GlobalContext.js" /* webpackChunkName: "component---src-pages-global-context-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-v-1-js": () => import("./../../../src/pages/index_V1.js" /* webpackChunkName: "component---src-pages-index-v-1-js" */),
  "component---src-pages-other-js": () => import("./../../../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-projects-cloud-light-js": () => import("./../../../src/pages/projects/cloud-light.js" /* webpackChunkName: "component---src-pages-projects-cloud-light-js" */),
  "component---src-pages-projects-compass-pattern-generators-js": () => import("./../../../src/pages/projects/compass-pattern-generators.js" /* webpackChunkName: "component---src-pages-projects-compass-pattern-generators-js" */),
  "component---src-pages-projects-compass-sign-center-js": () => import("./../../../src/pages/projects/compass-sign-center.js" /* webpackChunkName: "component---src-pages-projects-compass-sign-center-js" */),
  "component---src-pages-projects-contour-js": () => import("./../../../src/pages/projects/contour.js" /* webpackChunkName: "component---src-pages-projects-contour-js" */),
  "component---src-pages-projects-dovetail-js": () => import("./../../../src/pages/projects/dovetail.js" /* webpackChunkName: "component---src-pages-projects-dovetail-js" */),
  "component---src-pages-projects-drawing-objects-js": () => import("./../../../src/pages/projects/drawing-objects.js" /* webpackChunkName: "component---src-pages-projects-drawing-objects-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-light-clock-js": () => import("./../../../src/pages/projects/light-clock.js" /* webpackChunkName: "component---src-pages-projects-light-clock-js" */),
  "component---src-pages-projects-light-sculpture-js": () => import("./../../../src/pages/projects/light-sculpture.js" /* webpackChunkName: "component---src-pages-projects-light-sculpture-js" */),
  "component---src-pages-projects-refraction-study-js": () => import("./../../../src/pages/projects/refraction-study.js" /* webpackChunkName: "component---src-pages-projects-refraction-study-js" */),
  "component---src-pages-projects-reticulate-js": () => import("./../../../src/pages/projects/reticulate.js" /* webpackChunkName: "component---src-pages-projects-reticulate-js" */),
  "component---src-pages-projects-schnitzer-exhibition-js": () => import("./../../../src/pages/projects/schnitzer-exhibition.js" /* webpackChunkName: "component---src-pages-projects-schnitzer-exhibition-js" */)
}

